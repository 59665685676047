import React, { useContext, useState } from 'react';
import { Wrapper } from './../../../styles/generalStyles';
import { ReactComponent as MenuSVG } from './../../../icons/menu.svg';
import { ReactComponent as PlusSVG } from './../../../icons/plus.svg';
import { MobileHeaderContainer } from './styles/sMobileHeader';
import Navbar from './../navbar/Navbar';
import { createTitle } from '../../../contexts/algorithms/createTitle';

//Import Contexts
import { ChatContext } from '../../../contexts/chatContext';
import { AppContext } from '../../../App';

export default function MobileHeader() {
	const { modelUsed } = useContext(AppContext);
	const { chatSelected, setChatSelected, setTitles } = useContext(ChatContext);
	const [openNavbar, setOpenNavbar] = useState(false);

	return (
		<>
			<MobileHeaderContainer>
				<Wrapper>
					<div className="headerContent">
						<MenuSVG className="icon" onClick={() => setOpenNavbar(!openNavbar)} />
						<p className="truncate">{chatSelected?.title}</p>
						<PlusSVG
							className="icon"
							onClick={() =>
								createTitle({
									setChatSelected,
									setTitles,
									modelUsed,
								})
							}
						/>
					</div>
				</Wrapper>
			</MobileHeaderContainer>
			{openNavbar && <Navbar setOpenNavbar={setOpenNavbar} openNavbar={openNavbar} />}
		</>
	);
}
