import React from 'react';
import { Helmet } from 'react-helmet';
import MainLogin from './../components/login/MainLogin';

export default function Login() {
	return (
		<>
			<Helmet>
				<title>Iniciar sesión | Ebbiner</title>
				<meta name="description" content="Iniciar sesión" />
				<meta property="og:title" content="Iniciar sesión | Ebbiner" />
				<meta property="og:description" content="Iniciar sesión" />
				<meta property="twitter:title" content="Iniciar sesión | Ebbiner" />
				<meta property="twitter:description" content="Iniciar sesión" />
			</Helmet>
			<MainLogin />
		</>
	);
}
