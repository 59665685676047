import { useState, useEffect, createContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { onAuthStateChanged } from 'firebase/auth';
import './App.css';
import { FirestoreProvider } from 'reactfire';
import ChatModel from './pages/ChatModel';
import GlobalStyles from './theme/GlobalStyles';
import theme from './theme/Theme';
import MobileHeaderLayout from './components/layout/MobileHeaderLayout';
import { auth, db, enablePersistence } from './firebase/config';
import { getDataOfUser } from './algorithms/getDataOfUser';
import { ProtectedRoute } from './routes/ProtectedRoute';
import MainSpinner from './components/spinner/MainSpinner';

//Import routes
import { authRoutes } from './routes/ListOfRoutes';

//Import Contexts
import { ChatProvider } from './contexts/chatContext';

//Import constants
import { IA_MODELS } from './constants/IAModels';

const AppContext = createContext(undefined);
const { Provider: AppProvider, Consumer } = AppContext;

function App() {
	const [user, setUser] = useState(auth.currentUser || null);
	const [dataOfUser, setDataOfUser] = useState();
	const [loading, setLoading] = useState({ status: true, title: null });
	const savedModel = localStorage.getItem('model');
	const defaultModel = IA_MODELS[0];
	const [modelUsed, setModelUsed] = useState(savedModel ? JSON.parse(savedModel) : defaultModel);

	useEffect(() => {
		!user &&
			onAuthStateChanged(auth, async (userInfo) => {
				setLoading({ status: true, title: null });
				if (userInfo) {
					setUser(userInfo);
					await getDataOfUser({ db, userInfo, setDataOfUser });
				} else {
					setUser(null);
				}
				setLoading({ status: false, title: null });
			});
		enablePersistence();
	}, [user, db]);

	const appValue = {
		setDataOfUser,
		dataOfUser,
		user,
		setUser,
		setModelUsed,
		modelUsed,
		setLoading,
	};

	if ((loading.status && !user) || loading.status) {
		return <MainSpinner title={loading.title || 'Recopilando datos de usuario...'} />;
	}

	return (
		<FirestoreProvider sdk={db}>
			<AppProvider value={appValue}>
				<ThemeProvider theme={theme}>
					<ChatProvider>
						<GlobalStyles />
						<Routes>
							{authRoutes.map((route) => (
								<Route key={route.path} path={route.path} element={<ProtectedRoute>{route.element}</ProtectedRoute>} />
							))}
							<Route
								path="/chat"
								element={
									<ProtectedRoute>
										<MobileHeaderLayout children={<ChatModel />} />
									</ProtectedRoute>
								}
							/>
							{/* chat.ebbiner.com */}
							<Route path="*" element={<p>404</p>} />
							<Route path="*" element={<Navigate to="/404" />} />
						</Routes>
					</ChatProvider>
				</ThemeProvider>
			</AppProvider>
		</FirestoreProvider>
	);
}

export { App, Consumer as AppConsumer, AppContext };
