import React from 'react';
import { parseTextAndCode } from './algorithms/parseTextAndCode';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function TextWithCode({ text }) {
	const parsedContent = parseTextAndCode(text);
	const copyToClipboard = (str) => {
		const textArea = document.createElement('textarea');
		textArea.value = str;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
	};
	return (
		<div>
			{parsedContent.map((item, idx) => {
				if (item.type === 'text') {
					return <p key={idx}>{item.content}</p>;
				} else if (item.type === 'code') {
					return (
						<pre key={idx}>
							<div className="headerHighlighter">
								<h4>{item.language}</h4>
								<button type="button" onClick={() => copyToClipboard(String(item.content).replace(/\n$/, ''))}>
									Copiar código
								</button>
							</div>
							<SyntaxHighlighter
								children={String(item.content).replace('\n', '')}
								style={atomDark}
								language={item.language}
								PreTag="div"
								className="highlighter"
							/>
						</pre>
					);
				} else {
					return null;
				}
			})}
		</div>
	);
}
