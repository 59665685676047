import React, { useState, useEffect, useRef } from 'react';
import { Button } from './../../../styles/buttonGeneral';
import { ReactComponent as HelpSVG } from './../../../icons/help.svg';
import { ReactComponent as CloseSVG } from './../../../icons/close.svg';

export default function ChatSection({ titles, setChatSelected, chatSelected }) {
	const [hoverUnlist, setHoverUnlist] = useState(false);
	const unlistRef = useRef(null);

	useEffect(() => {
		if (unlistRef.current) {
			const listHeight = unlistRef.current.offsetHeight;
			const contentHeight = Array.from(unlistRef.current.children).reduce((height, child) => height + child.offsetHeight, 10);
			setHoverUnlist(contentHeight > listHeight);
		}
	}, [titles.length, unlistRef?.current?.offsetHeight]);

	return (
		<ul className="chatsSection" ref={unlistRef} style={{ overflowY: hoverUnlist ? 'scroll' : 'hidden' }}>
			{titles.length > 0 &&
				titles.map((chat) => (
					<li key={chat.chatId}>
						<Button
							chat
							selectChat={chatSelected.chatId == chat.chatId}
							onClick={() => {
								setChatSelected(chat);
							}}>
							<HelpSVG className="icon" />
							<p>{chat.title}</p>
							<CloseSVG className="icon close" />
						</Button>
					</li>
				))}
		</ul>
	);
}
