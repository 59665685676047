import { setDoc, doc, serverTimestamp } from 'firebase/firestore';

export const sendTitle = async ({ db, user, modelUsed, chatId, title }) => {
	await setDoc(
		doc(db, 'AIColaboratorsChats', user.uid, modelUsed.id, chatId),
		{
			title,
			chatId,
			model: modelUsed.id,
			createAt: serverTimestamp(),
		},
		{ merge: true },
	);
};
