import { modelRequest } from '../../components/general/footer/algorithms/modelRequest';
import { sendTitle } from './sendTitle';
import { uuidv4 } from '@firebase/util';

export const createTitle = async ({
	model = 'gpt-3.5-turbo',
	userText = null,
	setTitles,
	setChatSelected,
	db = null,
	user = null,
	modelUsed,
	chatId = uuidv4(),
}) => {
	const data = userText
		? await modelRequest({
				model,
				messages: [{ content: `Crea un título muy corto en base al siguiente texto: ${userText}`, role: 'user' }],
				user,
		  })
		: { text: '' };

	data.text = data.text.replace(/['".]+/g, '');
	userText &&
		db &&
		user &&
		sendTitle({
			db,
			user,
			modelUsed,
			chatId,
			title: data.text,
		});

	setTitles((prev) => {
		return [
			{ title: data.text, chatId, model: modelUsed.id },
			...prev.filter((p) => p.title.length > 0 && modelUsed.id === p.model),
		];
	});
	setChatSelected({ chatId, title: data.text });
};
