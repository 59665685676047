import { createContext, useState, useContext, useEffect } from 'react';
import { AppContext } from '../App';
import { useFirestore } from 'reactfire';
import { getChats } from './algorithms/getChats';
import Mainspinner from '../components/spinner/MainSpinner';

const ChatContext = createContext({});

const ChatProvider = ({ children }) => {
	const { modelUsed, user } = useContext(AppContext);
	const db = useFirestore();
	const [human, setHuman] = useState(true);
	const [chats, setChats] = useState({});
	const [titles, setTitles] = useState([]);
	const [chatSelected, setChatSelected] = useState({
		chatId: null,
		title: null,
	});
	const [changeModel, setChangeModel] = useState(false);
	const [chatLoading, setChatLoading] = useState({
		status: true,
		title: null,
	});

	useEffect(() => {
		if (user) {
			getChats({
				setChats,
				chats,
				setTitles,
				titles,
				setChatSelected,
				chatSelectedId: chatSelected.chatId,
				changeModel,
				setChatLoading,
				modelUsed,
				db,
				user,
			});
			setChangeModel(false);
		} else {
			setChatLoading({ status: false, title: null });
		}
	}, [modelUsed, chatSelected]);

	const value = {
		chats,
		setChats,
		titles,
		setTitles,
		chatSelected,
		setChatSelected,
		human,
		setHuman,
		setChangeModel,
	};
	if (chatLoading.status) {
		return <Mainspinner title={chatLoading.title || 'Recopilando datos del chat...'} />;
	}

	return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export { ChatContext, ChatProvider };
