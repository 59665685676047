import styled, { css } from 'styled-components';

export const MessageContainer = styled.li`
	position: relative;
	padding: 15px 20px 15px 35px;
	border-radius: 14px;
	margin: 0 0 15px 16px;
	font-family: ${(props) => props.theme.textFont};
	line-height: 22px;
	color: ${(props) => props.theme.gray1200};

	.typing p {
		display: inline-block;
		opacity: 0;
		animation: typing 4.5s ease-in-out infinite;
	}

	.headerHighlighter {
		background: ${(props) => props.theme.white1400};
		display: flex;
		padding: 10px 15px;
		justify-content: space-between;
		border-radius: 8px 8px 0 0 !important;
		font-size: 14.5px;

		button {
			background: none;
			border: none;
			font-family: ${(props) => props.theme.textFont};
			color: ${(props) => props.theme.gray1200};
			font-weight: ${(props) => props.theme.weight.regular};
			cursor: pointer;
			transition: all 0.2s;
			font-size: 14.5px;

			&:hover {
				color: ${(props) => props.theme.brandColor};
			}
		}
	}

	.highlighter {
		margin: 0 !important;
		border-radius: 0 0 8px 8px !important;
	}

	.typing p:nth-child(1) {
		animation-delay: 0.1s;
	}
	.typing p:nth-child(2) {
		animation-delay: 0.2s;
	}
	.typing p:nth-child(3) {
		animation-delay: 0.3s;
	}
	.typing p:nth-child(4) {
		animation-delay: 0.4s;
	}
	.typing p:nth-child(5) {
		animation-delay: 0.5s;
	}
	.typing p:nth-child(6) {
		animation-delay: 0.6s;
	}
	.typing p:nth-child(7) {
		animation-delay: 0.7s;
	}
	.typing p:nth-child(8) {
		animation-delay: 0.8s;
	}
	.typing p:nth-child(9) {
		animation-delay: 0.9s;
	}
	.typing p:nth-child(10) {
		animation-delay: 1s;
	}
	.typing p:nth-child(11) {
		animation-delay: 1.1s;
	}
	.typing p:nth-child(12) {
		animation-delay: 1.2s;
	}
	.typing p:nth-child(13) {
		animation-delay: 1.3s;
	}
	.typing p:nth-child(14) {
		animation-delay: 1.4s;
	}

	@keyframes typing {
		0% {
			opacity: 0;
			transform: translateY(10px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	img {
		border-radius: 50px;
		position: absolute;
		left: -16px;
		width: 38px;
		top: 5px;
	}

	${(props) =>
		props.type === 'true'
			? css`
					background: ${(props) => props.theme.black1400};
			  `
			: css`
					background: rgba(0, 208, 187, 0.025);
			  `}

	@media (min-width: 1200px) {
		padding: 25px 40px;
		margin: 0 0 20px 16px;
		line-height: 25px;

		img {
			border-radius: 50px;
			position: absolute;
			left: -16px;
			width: 38px;
			top: 17px;
		}
	}
`;
