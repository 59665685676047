import React, { useState, useRef, useContext } from 'react';
import { SelectContainer } from './styles/sSelect';
import { ReactComponent as ArrowSelectSVG } from './../../../icons/arrow-select.svg';

//Import constants
import { IA_MODELS } from '../../../constants/IAModels';

//Import contexts
import { AppContext } from '../../../App';
import { ChatContext } from '../../../contexts/chatContext';

export default function Select() {
	const { modelUsed, setModelUsed } = useContext(AppContext);
	const { setChangeModel } = useContext(ChatContext);
	const [rotate, setRotate] = useState(false);
	const selectRef = useRef(null);

	const handleChange = (event) => {
		const model = IA_MODELS.filter((v) => v.value === event.target.getAttribute('data-value'))[0];
		setChangeModel(true);
		setModelUsed(model);
		localStorage.setItem('model', JSON.stringify(model));
	};

	const selectEvent = () => {
		selectRef.current.classList.toggle('show');
		setRotate(!rotate);
	};

	return (
		<SelectContainer onClick={selectEvent} rotates={rotate}>
			<div className="selected">{modelUsed.value}</div>
			<div id="options" className="options" ref={selectRef}>
				{IA_MODELS.map((option) => (
					<div
						key={option.id}
						className={`option ${modelUsed === option.value ? 'selected' : ''}`}
						data-value={option.value}
						onClick={handleChange}>
						{option.label}
					</div>
				))}
			</div>
			<ArrowSelectSVG />
		</SelectContainer>
	);
}
