import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';
import { doc, arrayUnion, writeBatch, query, where, collection, getDocs, deleteField } from 'firebase/firestore';
export const signInWithGoogle = async ({ db, setLoading, setUser, navigate }) => {
	try {
		const provider = new GoogleAuthProvider();
		const auth = getAuth();
		auth.languageCode = 'es';
		await signInWithPopup(auth, provider).then(async (res) => {
			setLoading({ status: true, title: null });
			const user = await res.user;
			const data = {
				displayName: user.displayName,
				email: user.email,
				createdAt: user.metadata?.createdAt,
				lastLoginAt: user.metadata?.lastLoginAt,
				photoURL: user.photoURL.replace('s96-c', 's400-c'),
			};
			await sendDataUserFromGoogle({
				data,
				uid: user.uid,
				db,
				setUser,
				auth,
			});
			navigate('/chat', { replace: true });
			return setLoading({ status: false, title: null });
		});
	} catch (err) {
		console.error(err);
	}
};

const sendDataUserFromGoogle = async ({ data, uid, db, setUser, auth }) => {
	try {
		const { displayName, email, createdAt, lastLoginAt, photoURL } = data;
		const batch = await writeBatch(db);
		const thisUserRef = doc(db, 'users', uid);

		//referencia a la coleccion usersLogs
		const thisUserLogRef = doc(db, 'usersLogs', uid);

		const recoverQuery = await query(collection(db, 'users'), where('uid', '==', uid));
		const querySnapshot = await getDocs(recoverQuery);
		if (querySnapshot.empty) {
			batch.set(
				thisUserRef,
				{
					username: displayName,
					name: {
						firstName: '',
						lastName: '',
					},
					email,
					uid,
					photoURL,
					universityAcronym: 'UNSCH',
					unversityName: 'Universidad Nacional de San Cristóbal de Huamanga',
					subscriptionData: {
						endTimestamp: null,
					},
					profileEdited: {
						status: false,
						sleepTime: null,
					},
				},
				{ merge: true },
			);
			batch.set(
				thisUserLogRef,
				{
					uid,
					username: displayName,
					metadata: {
						createdAt: parseInt(createdAt),
						listOfLogins: arrayUnion(parseInt(lastLoginAt)),
					},
				},
				{ merge: true },
			);
		} else {
			const userDoc = querySnapshot.docs[0];
			const userDocData = userDoc.data();
			if (userDocData.metadata) {
				batch.update(thisUserRef, {
					metadata: deleteField(),
				});
			}
			batch.set(
				thisUserLogRef,
				{
					metadata: {
						createdAt: parseInt(createdAt),
						listOfLogins: arrayUnion(parseInt(lastLoginAt)),
					},
				},
				{ merge: true },
			);
		}

		await batch
			.commit()
			.then(() => {
				setUser(auth.currentUser);
			})
			.catch((err) => {
				console.error(err.message);
			});
	} catch (err) {
		console.error(err.message);
	}
};
