import React, { useState, useRef, useEffect, useContext } from 'react';
import { Button } from './../../../styles/buttonGeneral';
import Logo from './../logo/Logo';
import { NavbarContainer, Overlay } from './styles/sNavbar';
import { ReactComponent as PlusSVG } from './../../../icons/plus.svg';
import { ReactComponent as DoorCloseSVG } from './../../../icons/door-close.svg';
import { ReactComponent as CloseSVG } from './../../../icons/close.svg';
import Select from './../cOthers/Select';
import { SignOut } from './../../../components/login/algorithms/SignOut';
import { createTitle } from '../../../contexts/algorithms/createTitle';
import ChatSection from './ChatSection';

//Import Contexts
import { ChatContext } from '../../../contexts/chatContext';
import { AppContext } from '../../../App';

export default function Navbar({ setOpenNavbar, openNavbar }) {
	const { titles, setChatSelected, setTitles, chatSelected } = useContext(ChatContext);
	const { modelUsed } = useContext(AppContext);
	const [height, setHeight] = useState(0);
	const confRef = useRef(null);
	const logoModelRef = useRef(null);

	useEffect(() => {
		const confSection = confRef.current;
		const logoModelSection = logoModelRef.current;
		setHeight(confSection.offsetHeight + logoModelSection.offsetHeight + 215);
	}, []);

	return (
		<div>
			<NavbarContainer delHeight={height} className={`${openNavbar && 'showNavbar'}`}>
				<div ref={logoModelRef}>
					<Logo />
					<section>
						<h3>Modelo</h3>
						<Select />
					</section>
				</div>
				<section>
					<h3>Chats</h3>
					<Button
						addChat
						onClick={() =>
							createTitle({
								setChatSelected,
								setTitles,
								modelUsed,
							})
						}>
						<PlusSVG className="icon" />
						Nuevo chat
					</Button>
					<ChatSection titles={titles} setChatSelected={setChatSelected} chatSelected={chatSelected} />
				</section>
				<section className="configurations" ref={confRef}>
					<h3>Configuraciones</h3>
					<ul>
						<li>
							<Button navbarItem onClick={SignOut}>
								<DoorCloseSVG className="icon" />
								Cerrar sesión
							</Button>
						</li>
					</ul>
				</section>
			</NavbarContainer>
			<Overlay onClick={() => setOpenNavbar(!openNavbar)}>
				<CloseSVG className="icon close" />
			</Overlay>
		</div>
	);
}
