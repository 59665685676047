import styled, { css } from 'styled-components';

export const mixinChat = css`
	padding: 10px;
	display: flex;
	font-size: 15px;
	color: ${(props) => props.theme.gray1200};
	align-items: center;

	svg {
		margin: 0 10px 0 0;
	}

	@media (min-width: 1200px) {
		font-size: 14px;
	}
`;

export const Button = styled.button`
	border-radius: 14px;
	border: none;
	margin: 0 auto;
	cursor: pointer;
	outline: none;
	font-weight: ${(props) => props.theme.weight.semiBold};
	width: 100%;
	font-family: ${(props) => props.theme.titleFont};
	height: 44px;
	font-size: 1.035em;
	transition: 0.2s;
	will-change: transform;

	:disabled {
		background: ${(props) => props.theme.white1700};

		&:hover {
			background: ${(props) => props.theme.gray1500};
		}
	}

	${(props) =>
		props.primary &&
		css`
			background: ${(props) => props.theme.brandColor};
			color: ${(props) => props.theme.backgroundColor};

			&:hover {
				background: ${(props) => props.theme.blue700};
			}
		`}

	${(props) =>
		props.secondary &&
		css`
			background: transparent;
			color: ${(props) => props.theme.brandColor};
			border: 1px solid ${(props) => props.theme.brandColor};

			&:hover {
				background: ${(props) => props.theme.blue100};
			}
		`}

  ${(props) =>
		props.tertiary &&
		css`
			background: ${(props) => props.theme.white1500};
			color: ${(props) => props.theme.backgroundColor};
			text-transform: initial;
			height: initial !important;
			padding: 7px 15px;

			&:hover {
				color: ${(props) => props.theme.blue700};
			}
		`}

  ${(props) =>
		props.quaternary &&
		css`
			background: transparent;
			color: ${(props) => props.theme.textColor};
			text-transform: initial;
			border: 1px solid ${(props) => props.theme.textColor};
			height: 34px;
			width: auto;
			padding: 0 20px;
			font-size: 0.938em;

			&:hover {
				background: ${(props) => props.theme.gray0Color};
			}

			@media (min-width: 768px) {
				height: 40px !important;
				padding: 0 20px !important;
			}
		`}

  ${(props) =>
		props.fifth &&
		css`
			background: #ebebfa;
			color: ${(props) => props.theme.purpleColor};
			text-transform: initial;
			height: 34px;
			width: auto;
			padding: 0 20px;
			font-size: 0.938em;

			&:hover {
				background: ${(props) => props.theme.blue700};
			}

			@media (min-width: 768px) {
				height: 40px !important;
				padding: 0 20px !important;
			}
		`}

	${(props) =>
		props.sixth &&
		css`
			background: ${(props) => props.theme.brandColor};
			color: ${(props) => props.theme.backgroundColor};
			text-transform: initial;
			height: 34px;
			width: auto;
			padding: 0 20px;
			font-size: 0.938em;

			&:hover {
				background: #d9d9f5;
			}

			@media (min-width: 768px) {
				height: 40px !important;
				padding: 0 20px !important;
			}
		`}

  ${(props) =>
		props.purple &&
		css`
			background: ${(props) => props.theme.purple500 + '1a'};
			color: ${(props) => props.theme.purple200};

			&:hover {
				background: ${(props) => props.theme.purple200};
				color: ${(props) => props.theme.purple500};
			}
		`}


  ${(props) =>
		props.whatsApp &&
		css`
			background: #128c7e;
			color: ${(props) => props.theme.backgroundColor};

			&:hover {
				background: #075e54;
			}
		`}

  ${(props) =>
		props.small &&
		css`
			height: 34px;
			padding: 0 20px;
			font-size: 0.938em;
		`}

  ${(props) =>
		props.loginLanding &&
		css`
			background: transparent;
			color: ${(props) => props.theme.backgroundColor};
			border: 1px solid ${(props) => props.theme.backgroundColor};
			margin: 0 !important;
			padding: 0 10px !important;
			width: auto !important;
			height: 34px !important;
			text-transform: initial;

			&:hover {
				background: ${(props) => props.theme.backgroundColor};
				color: ${(props) => props.theme.textColor};
			}
		`}

  ${(props) =>
		props.google &&
		css`
			background: #db4a39;
			color: ${(props) => props.theme.backgroundColor};
			text-transform: initial;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 20px;
				margin: 10px;
			}

			&:hover {
				background: #b93a2b;
				color: ${(props) => props.theme.backgroundColor};
			}

			@media (min-width: 1024px) {
				display: flex !important;
			}
		`}

	${(props) =>
		props.payment &&
		css`
			background: ${(props) => props.theme.red500};
			color: ${(props) => props.theme.backgroundColor};

			&:hover {
				background: #b93a2b;
			}
		`}

  ${(props) =>
		props.inForm &&
		css`
			margin: 10px 0 0 0;
		`}

  ${(props) =>
		props.formEnd &&
		css`
			margin: 30px 0 0 0;
		`}

	${(props) =>
		props.addChat &&
		css`
			${mixinChat}

			background: ${(props) => props.theme.white1500};
			font-weight: ${(props) => props.theme.weight.medium};

			&:hover {
				background: ${(props) => props.theme.gray1500};
			}
		`}

	${(props) =>
		props.chat &&
		css`
			${mixinChat}

			background: ${(props) => props.theme.white1300};
			font-weight: ${(props) => props.theme.weight.regular};
			transition: all 0.2s;

			.close {
				display: none;
				position: absolute;
				right: 0;

				&:hover {
					fill: ${(props) => props.theme.brandColor};
				}
			}

			&:focus {
				.close {
					display: block;
				}
			}

			&:hover {
				background: ${(props) => props.theme.white1400};

				.close {
					display: block;
				}
			}
		`}

	${(props) =>
		props.selectChat &&
		css`
			background: ${(props) => props.theme.white1400} !important;

			p {
				padding-right: 24px;
			}

			.close {
				display: block;
			}
		`}

	${(props) =>
		props.navbarItem &&
		css`
			${mixinChat}

			background: transparent;
			font-weight: ${(props) => props.theme.weight.regular};
			transition: all 0.2s;

			&:hover {
				background: ${(props) => props.theme.white1400};
			}

			@media (min-width: 1200px) {
				width: 240px;
			}
		`}
`;
