import React, { useState, useRef, useContext } from 'react';
import { Wrapper } from './../../../styles/generalStyles';
import { FormContainer, InputContainer } from './../../../styles/inputGeneral';
import { FooterContainer } from './styles/sFooterChat';
import { ReactComponent as PointerSVG } from './../../../icons/pointer.svg';
import { uuidv4 } from '@firebase/util';
import { serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { updateChat } from './algorithms/updateChat';
import { modelRequest } from './algorithms/modelRequest';
import { chatPromptConstructor } from './algorithms/chatPromptConstructor';

//Import contexts
import { AppContext } from '../../../App';
import { ChatContext } from '../../../contexts/chatContext';
import { createTitle } from '../../../contexts/algorithms/createTitle';

export default function FooterChat() {
	const [height, setHeight] = useState(40);
	const [localMessage, setLocalMessage] = useState(null);
	const { user, modelUsed } = useContext(AppContext);
	const { setChats, chats, chatSelected, setChatSelected, human, setHuman, setTitles } = useContext(ChatContext);
	const textAreaRef = useRef(null);

	const handleInputChange = (e) => {
		setHeight(`${textAreaRef.current.scrollHeight}`);
		setLocalMessage(e.target.value);
	};

	const handleSendMessage = async (e) => {
		if (!(e.key === 'Enter' && e.ctrlKey) && !(e.type === 'click')) {
			return;
		}
		if (!localMessage || String(localMessage).replaceAll('\n', '').trim().length === 0) {
			return;
		}

		setHeight(40);
		if (chatSelected.title.length === 0) {
			createTitle({
				userText: localMessage,
				setTitles,
				setChatSelected,
				db,
				user,
				modelUsed,
				chatId: chatSelected.chatId,
			});
		}

		let message = {
			messageId: uuidv4(),
			human,
			text: localMessage,
			createAt: serverTimestamp(),
		};
		updateChat({
			message,
			setChats,
			modelUsed,
			chatSelected,
			db,
			user,
			setLocalMessage,
			textAreaRef,
		});
		setHuman(false);
		if (human) {
			const data = await modelRequest({
				model: modelUsed.id,
				messages: chatPromptConstructor({ chats: [...chats[modelUsed.id][chatSelected.chatId], message], ...modelUsed }),
				user,
			});
			if (!data.status) {
				setChats((prev) => {
					return {
						...prev,
						[modelUsed.id]: {
							...prev[modelUsed.id],
							[chatSelected.chatId]: [
								...prev[modelUsed.id][chatSelected.chatId],
								{
									messageId: uuidv4(),
									status: data.status,
									human: false,
									text: data.text,
									createAt: serverTimestamp().toDate(),
								},
							],
						},
					};
				});
				return setHuman(true);
			}
			updateChat({
				message: {
					messageId: uuidv4(),
					human: false,
					createAt: serverTimestamp(),
					...data,
				},
				setChats,
				modelUsed,
				chatSelected,
				db,
				user,
				setLocalMessage,
				textAreaRef,
			});
			setHuman(true);
		}
	};

	return (
		<FooterContainer>
			<Wrapper input>
				<FormContainer>
					<InputContainer icon height={height}>
						<textarea
							placeholder="Inicia un chat..."
							ref={textAreaRef}
							disabled={!human}
							onInput={handleInputChange}
							onChange={(e) => String(e.target.value).trim().length === 0 && setHeight(40)}
							onKeyDown={(e) => handleSendMessage(e)}
						/>
						<PointerSVG className="icon color" onClick={(e) => handleSendMessage(e)} />
					</InputContainer>
				</FormContainer>
			</Wrapper>
		</FooterContainer>
	);
}
