import React from 'react';
import { MessageContainer } from './styles/sMessage';
import TextWithCode from './TextWithCode';

export default function Message({ name, text, human, user, status = true }) {
	return (
		<MessageContainer type={human.toString()}>
			<img src={!human ? require('./../../images/kat-profile.jpg') : user?.photoURL} alt={name} />
			{text !== 'Escribiendo...' ? (
				<TextWithCode text={text} />
			) : (
				<div className="typing">
					{text.split('').map((e, index) => (
						<p key={`${e}-${index}`}>{e}</p>
					))}
				</div>
			)}
			{!status && <button>Reintentar...</button>}
		</MessageContainer>
	);
}
