import React from 'react';
import MobileHeader from './../general/header/MobileHeader';

export default function MobileHeaderLayout({ children }) {
	return (
		<>
			<MobileHeader />
			{children}
		</>
	);
}
