import styled from 'styled-components';
import { mixinChat } from './../../../../styles/buttonGeneral';

export const SelectContainer = styled.div`
	${mixinChat}

	position: relative;
	cursor: pointer;
	background: ${(props) => props.theme.white1500};
	font-weight: ${(props) => props.theme.weight.medium};
	border-radius: 14px;
	justify-content: space-between;
	transition: all 0.2s;

	svg {
		transform: rotate(${(props) => props.rotates && '180deg'});
		transition: all 0.2s;
	}

	.options {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		max-height: 150px;
		overflow-y: auto;
		background-color: ${(props) => props.theme.black1400};
		z-index: 1;
		border-radius: 14px;
		opacity: 0;
	}

	.show {
		opacity: 1;
		height: fit-content;
		overflow: hidden;
		transition: opacity 0.1s ease-in-out, height 0.1s ease-in-out;
	}

	.option {
		padding: 8px;
	}

	.option:hover {
		background-color: ${(props) => props.theme.brandColor};
		transition: all 0.1s;
	}

	&:hover {
		background: ${(props) => props.theme.gray1500};
	}

	@media (min-width: 1200px) {
		font-size: 13px;
	}
`;
