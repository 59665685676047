import { sendMessage } from './sendMessage';

export const updateChat = ({ message, setChats, modelUsed, chatSelected, db, user, setLocalMessage, textAreaRef }) => {
	setChats((prev) => {
		return {
			...prev,
			[modelUsed.id]: {
				[chatSelected.chatId]: [...(prev?.[modelUsed.id]?.[chatSelected.chatId] ?? []), message],
			},
		};
	});
	sendMessage({
		db,
		message,
		modelUsed,
		user,
		chatSelected,
	});
	setLocalMessage(null);
	textAreaRef.current.value = null;
};
