import { getDocs, collection, query, orderBy, limit } from 'firebase/firestore';
import { createTitle } from './createTitle';

export const getTitles = async ({ setTitles, setChatSelected, modelUsed, db, user }) => {
	const chats = await getDocs(
		query(collection(db, 'AIColaboratorsChats', user.uid, modelUsed.id), orderBy('createAt', 'desc'), limit(15)),
	);
	const recoveredTitles = chats.docs.map((doc) => {
		return {
			...doc.data(),
			...(doc.data()?.createAt ? { createAt: doc.data().createAt.toDate() } : {}),
		};
	});
	if (recoveredTitles.length > 0) {
		const titlesSorted = recoveredTitles.sort((a, b) => b.createAt - a.createAt);
		setTitles(titlesSorted);
		return titlesSorted;
	} else {
		createTitle({
			setTitles,
			setChatSelected,
			modelUsed,
		});
	}
	return null;
};
