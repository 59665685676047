import styled from 'styled-components';
import { MixinBox } from './../../../styles/generalStyles';
import { Button } from './../../../styles/buttonGeneral';

export const MainLoginContainer = styled.div`
	${Button} {
		${MixinBox}
	}

	position: relative;
	z-index: 10;

	.logo {
		margin: 0 auto 30px auto;
	}
`;
