import React, { useContext } from 'react';
import { Text } from './../../styles/textGeneral';
import { ReactComponent as BoxSVG } from './../../icons/box.svg';
import { ReactComponent as MessageSVG } from './../../icons/message.svg';
import { ReactComponent as RocketSVG } from './../../icons/rocket.svg';
import { InitialChatContainer, UnlistInitialChat } from './styles/sInitialChat';

//Import Context
import { AppContext } from '../../App';

const initialList = [
	{
		title: 'Guías de uso',
		icon: <BoxSVG />,
		content: [
			{
				item: '¿Cómo debo comenzar a trabajar con el árbol de voz?',
			},
			{
				item: '¿Cómo funciona la generación de diapositivas?',
			},
			{
				item: 'Comience con la voz en árbol con "Hola"',
			},
		],
	},
	{
		title: 'Ejemplos',
		icon: <MessageSVG />,
		content: [
			{
				item: '“Explicar la computación cuántica en términos simples”',
			},
			{
				item: '“¿Tienes alguna idea creativa para el cumpleaños de un niño de 10 años?”',
			},
			{
				item: '“¿Cómo hago una solicitud HTTP en Javascript?”',
			},
		],
	},
	{
		title: 'Nuevas actualizaciones',
		icon: <RocketSVG />,
		content: [
			{
				item: '¿Cuáles son las nuevas actualizaciones?',
			},
			{
				type: 'link',
				link: '/updates',
				item: 'Ver todas las actualizaciones',
			},
		],
	},
];

export default function InitialChat() {
	const { modelUsed } = useContext(AppContext);

	return (
		<InitialChatContainer>
			<h1>Ebbiner {modelUsed.label}</h1>
			<div className="grid">
				{initialList.map((initial) => (
					<ul className="initialUnlist" key={initial.title}>
						<div>
							{initial.icon}
							<Text>{initial.title}</Text>
						</div>
						<UnlistInitialChat content={initial.content} />
					</ul>
				))}
			</div>
		</InitialChatContainer>
	);
}
