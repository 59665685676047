export const parseTextAndCode = (text) => {
	const regex = /```[\s\S]*?```/g;
	const codeBlocks = text.match(regex) || [];
	const textBlocks = text.split(regex);

	return textBlocks.reduce((result, textBlock, idx) => {
		result.push({ type: 'text', content: textBlock });

		if (codeBlocks[idx]) {
			const firstNewLine = codeBlocks[idx].indexOf('\n');
			const language = codeBlocks[idx].substring(3, firstNewLine).trim();
			const codeContent = codeBlocks[idx].substring(firstNewLine + 1, codeBlocks[idx].length - 3);
			const languageIsValid = language.length > 0 && language.length < 14;

			result.push({
				type: 'code',
				content: codeContent,
				language: languageIsValid ? language : 'script',
			});
		}

		return result;
	}, []);
};
