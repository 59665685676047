import Login from '../pages/Login';

export const authRoutes = [
	{
		path: '/',
		element: <Login />,
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/register',
		element: <Login />,
	},
];
