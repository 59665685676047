import React from 'react';
import { Helmet } from 'react-helmet';
import FooterChat from './../components/general/footer/FooterChat';
import MainChatModel from './../components/chatModel/MainChatModel';

export default function ChatModel() {
	return (
		<>
			<Helmet>
				<title>Ebbiner</title>
				<meta name="description" content="Ebbiner" />
				<meta property="og:title" content="Ebbiner" />
				<meta property="og:description" content="Ebbiner" />
				<meta property="twitter:title" content="Ebbiner" />
				<meta property="twitter:description" content="Ebbiner" />
			</Helmet>
			<MainChatModel />
			<FooterChat />
		</>
	);
}
