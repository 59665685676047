export const colors = {
	color: {
		brand: '#00D0BB',
		black: {
			b100: 'rgba(16, 17, 39, 0.1)',
			b500: '#010812',
			b700: '#1A2228',
			b1000: '#161E24',
			b1400: '#141C23',
			b1500: '#121A21',
		},
		white: {
			background: '#F4F3FA',
			full: '#F9F8FE',
			w1300: 'rgba(244, 243, 250, 0.02)',
			w1400: 'rgba(244, 243, 250, 0.05)',
			w1500: 'rgba(244, 243, 250, 0.1)',
			w1600: 'rgba(255, 255, 255, 0.1)',
			w1700: 'rgba(255, 255, 255, 0.2)',
		},
		purple: {
			p100: '#EFEAF9',
			p200: '#D2C0F5',
			p300: '#BFA6F1',
			p500: '#804DE7',
			p700: '#5026A6',
		},
		pink: {
			p500: '#F4A1C9',
			p700: '#650A36',
			p2000: '#D11EE1',
		},
		green: {
			g300: '#D9F6E1',
			g500: '#11A83C',
			g700: '#097828',
			g1000: '#C3FF86',
		},
		gray: {
			g000: '#E4D8ED',
			g100: '#CAC3CF',
			g200: '#A9A0AE',
			g300: '#7F7A82',
			g400: '#746F78',
			g500: '#48414F',
			g1100: '#D3D8DF',
			g1200: '#C3CBD7',
			g1400: '#7D8187',
			g1500: '#5E6672',
		},
		yellow: {
			y200: '#FFF6DE',
			y300: '#FFEBB6',
			y500: '#C28E08',
			y700: '#89660A',
		},
		blue: {
			b100: '#D8FFFB',
			b200: '#ADFDF5',
			b300: '#6EEBDE',
			b700: '#0B7267',
			b1500: 'rgba(25, 247, 224, 0.05)',
		},
		red: {
			r100: '#FFEFF0',
			r300: '#FFDCDC',
			r500: '#EF424C',
			r700: '#A30F17',
		},
		degrade: {
			brand: 'linear-gradient(153.62deg, #297DED 0%, #D11EE1 100.51%)',
		},
	},
	shadow: {
		card: '0px 2px 8px rgba(0, 0, 0, 0.15)',
	},
};

export const fonts = {
	brandFont: 'Quicksand',
	titleFont: 'Quicksand',
	textFont: 'Nunito',
	weight: {
		thin: 100,
		extraLight: 200,
		light: 300,
		regular: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
		extraBold: 800,
		black: 900,
	},
};
