import { doc, writeBatch, increment } from 'firebase/firestore';

export const sendMessage = ({ db, message, modelUsed, user, chatSelected }) => {
	const batch = writeBatch(db);
	const chatsRef = doc(db, 'AIColaboratorsChats', user.uid, modelUsed.id, chatSelected.chatId);
	const messageRef = doc(chatsRef, 'chats', message.messageId);
	batch.set(messageRef, message, { merge: true });
	if (message.human) {
		batch.set(chatsRef, { lastMessageAt: message.createAt, humanMessageCounter: increment(1) }, { merge: true });
	}
	batch.commit();
};
