import styled from 'styled-components';
import { Text } from './../../../styles/textGeneral';

export const InitialChatContainer = styled.section`
	h1 {
		font-family: ${(props) => props.theme.brandFont};
		font-size: 25px;
		font-weight: ${(props) => props.theme.weight.bold};
		text-align: center;
	}

	.initialUnlist {
		display: grid;
		gap: 10px;

		svg {
			margin: 40px auto 10px auto;
			display: block;
			width: 50px;
		}

		${Text} {
			text-align: center;
			color: ${(props) => props.theme.whiteColor};
			margin: 0 0 15px 0;
		}
	}

	@media (min-width: 1024px) {
		.initialUnlist {
			display: block;

			svg {
				margin: 55px auto 15px auto;
			}

			${Text} {
				margin: 0 0 30px 0;
			}
		}

		.grid {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 10px;
		}
	}
`;

export const UnlistInitialChat = styled(({ content, ...rest }) => (
	<ul {...rest}>
		{content.map((item) => {
			return <li key={item.item}>{item.item}</li>;
		})}
	</ul>
))`
	display: grid;
	gap: 10px;

	li {
		background: ${(props) => props.theme.black1400};
		box-shadow: ${(props) => props.theme.shadowCard};
		padding: 15px 25px;
		border-radius: 14px;
		line-height: 24px;
		text-align: center;
		color: ${(props) => props.theme.gray1200};
		font-family: ${(props) => props.theme.textFont};
	}

	@media (min-width: 1024px) {
		li {
			font-size: 14px;
			line-height: 20px;
		}
	}
`;
