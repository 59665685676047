export const modelRequest = async ({ model, messages, user, retries = 3, delay = 2000 }) => {
	try {
		const response = await fetch('https://api.openai.com/v1/chat/completions', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer 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`,
			},
			body: JSON.stringify({
				messages,
				model,
				user: user?.uid ?? 'anonymous',
			}),
		});

		if (!response.ok) {
			if (retries > 0) {
				setTimeout(() => modelRequest({ model, messages, user, retries: retries - 1 }), delay);
			} else {
				throw new Error(`Error en la petición: ${response.status}`);
			}
		}

		const data = await response.json();
		return {
			status: true,
			text: data.choices[0].message.content,
			usage: data.usage,
		};
	} catch (err) {
		if (retries > 0) {
			await new Promise((resolve) => setTimeout(resolve, delay));
			return modelRequest({ model, messages, user, retries: retries - 1 });
		} else {
			return {
				status: false,
				text: 'Error en la petición, por favor reintente :(',
				usage: null,
			};
		}
	}
};
