import { useContext } from 'react';
import { AppContext } from '../App';
import { useLocation, Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

export function ProtectedRoute({ children, redirectTo }) {
	const location = useLocation();
	const { currentUser } = getAuth();
	const { dataOfUser } = useContext(AppContext);
	const hasAccess = dataOfUser?.typeOfUser?.includes('colaborator');
	if (location.pathname === '/' || location.pathname === '/login' || location.pathname === '/register') {
		return currentUser && dataOfUser && hasAccess ? <Navigate to={location.state?.from ?? '/chat'} replace /> : children;
	}

	return currentUser && dataOfUser && hasAccess ? (
		children
	) : (
		<Navigate to={redirectTo || '/'} state={{ from: location.pathname }} replace />
	);
}
