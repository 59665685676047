import styled, { css } from 'styled-components';

const mixinWrapper = css`
	@media (min-width: 360px) {
		padding: 0 20px;
	}

	@media (min-width: 375px) {
		padding: 0 25px;
	}

	@media (min-width: 768px) {
		padding: 0 12%;
	}

	@media (min-width: 1024px) {
		padding: 0 10%;
	}
`;

export const MixinBox = css`
	@media (min-width: 360px) {
		width: calc(100vw - 40px);
	}

	@media (min-width: 375px) {
		width: calc(100vw - 50px);
	}

	@media (min-width: 768px) {
		width: calc(100vw - 36%);
	}

	@media (min-width: 1024px) {
		width: 300px;
	}
`;

export const Wrapper = styled.div`
	${mixinWrapper};
	${(props) =>
		props.chat &&
		css`
			margin: 70px 0 80px 0;

			@media (min-width: 1200px) {
				padding: 0;
				width: 800px;
				margin: 50px auto 100px auto;
			}
		`}

	${(props) =>
		props.input &&
		css`
			margin: 15px 0;

			@media (min-width: 1200px) {
				padding: 0;
				width: 800px;
				margin: 15px auto;
			}
		`}
`;

export const RegisterAndLoginWrapper = styled.div`
	${mixinWrapper};

	display: grid;
	place-content: center;
	height: 100vh;

	.background {
		${MixinBox}

		@media (min-width: 1024px) {
			width: 500px;
		}

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
`;
