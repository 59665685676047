import { ebbinV1 } from '../../../../constants/initialPrompts';

export const chatPromptConstructor = ({ chats, memory }) => {
	const memorizedChats = chats.slice(-memory);
	return memorizedChats.reduce((result, chat, index) => {
		if (index === 0) {
			result.push({ role: 'system', content: ebbinV1 });
		}
		if (chat.human) {
			result.push({ role: 'user', content: chat.text });
		} else {
			result.push({ role: 'assistant', content: chat.text });
		}
		return result;
	}, []);
};
