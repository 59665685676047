import React, { useState, useEffect, useContext, useRef } from 'react';
import Navbar from './../general/navbar/Navbar';
import { Wrapper } from './../../styles/generalStyles';
import InitialChat from './InitialChat';
import { GridDesktop } from './styles/sMainChatModel';
import Message from './Message';
//Import contexts
import { AppContext } from '../../App';
import { ChatContext } from '../../contexts/chatContext';

export default function MainChatModel() {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
	});
	const { user, modelUsed } = useContext(AppContext);
	const { chats, chatSelected, human } = useContext(ChatContext);
	const messagesEndRef = useRef();

	const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
			});
		}

		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		scrollToBottom();
	}, [human, chats]);

	return (
		<>
			<main>
				<GridDesktop>
					<div></div>
					<Wrapper chat>
						{!chatSelected.title || chatSelected.title?.length === 0 ? (
							<InitialChat />
						) : (
							<ul>
								{chats?.[modelUsed?.id]?.[chatSelected?.chatId]?.map((chat) => (
									<Message key={chat.messageId} user={user} {...chat} />
								))}
								{!human && <Message human={false} text={'Escribiendo...'} />}
							</ul>
						)}
						<span ref={messagesEndRef}></span>
					</Wrapper>
				</GridDesktop>
				{windowSize.width > 1200 && <Navbar />}
			</main>
		</>
	);
}
