import React from 'react';
import { ReactComponent as EbbinerLogo } from './../../../images/Ebbiner-horizontal-white.svg';
import { LogoContainer } from './styles/sLogo';

export default function Logo() {
	return (
		<LogoContainer to="/" className="logo">
			<EbbinerLogo />
		</LogoContainer>
	);
}
