import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { firebaseConfig } from './firebase/config';
import { FirebaseAppProvider } from 'reactfire';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import i18next from 'i18next';

//Import languages
import main_es from './lang/main-es.json';

const root = ReactDOM.createRoot(document.getElementById('root'));

i18next.init({
	interpolation: { escapeValue: false },
	lng: 'es',
	resources: {
		es: {
			main: main_es,
		},
	},
});

root.render(
	// <React.StrictMode>
	<FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
		<I18nextProvider i18n={i18next}>
			<BrowserRouter>
				<HelmetProvider>
					<App />
				</HelmetProvider>
			</BrowserRouter>
		</I18nextProvider>
	</FirebaseAppProvider>,
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
