import styled, { css } from 'styled-components';

export const mixinInput = css`
	background: ${(props) => props.theme.white1500};
	border: 1px solid ${(props) => props.theme.gray1400};
	border-radius: 14px;
	padding: 5px 10px;
	font-family: ${(props) => props.theme.textFont};
	font-size: 0.938em;
	color: ${(props) => props.theme.gray1200};
	transition: all 0.2s;
`;

export const mixinHoverAndFocus = css`
	&:hover {
		border: 1px solid ${(props) => props.theme.brandColor};
	}

	&:focus {
		outline: none;
		border: 1px solid ${(props) => props.theme.brandColor};
		background: ${(props) => props.theme.blue1500};
	}

	&:read-only {
		background: ${(props) => props.theme.white1500};

		&:hover {
			background: ${(props) => props.theme.white1600};
		}
	}
`;

export const FormContainer = styled.form`
	@media (min-width: 1200px) {
		.inputContainerDuplo {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 40px;
			width: 70%;
		}

		.inputContainerQuad {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			column-gap: 10px;
		}

		.inputContainerInRow {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
			gap: 20px;
		}

		.inputContainerInRowOne {
			display: grid;
			justify-content: center;
			grid-template-columns: repeat(auto-fit, minmax(200px, 40%));
		}

		.buttonsContainer {
			display: flex;
			justify-content: center;
			margin-top: 30px;
			gap: 10px;
		}
	}
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px 0 5px 0;
	position: relative;

	${(props) =>
		props.margin10B &&
		css`
			margin: 0 0 10px 0;
		`}

	${(props) =>
		props.margin20B &&
		css`
			margin: 0 0 20px 0;
		`}
	
	${(props) =>
		props.noMargin &&
		css`
			margin: 0;
		`}

	${(props) =>
		props.icon &&
		css`
			justify-content: space-between;
			flex-direction: row;
			gap: 10px;

			input,
			textarea {
				flex: 1 auto;
			}
		`}
	
	label {
		font-size: 0.938em;
		font-family: ${(props) => props.theme.textFont};
		font-weight: ${(props) => props.theme.weight.medium};
		margin: 0 0 5px 0;
		color: ${(props) => props.theme.backgroundColor};
	}

	input {
		${mixinInput}
		${mixinHoverAndFocus}
		
		height: 40px;
		margin: ${(props) => props.margin};
	}

	svg {
		transition: 0.2s all;

		&:hover {
			fill: ${(props) => props.theme.brandColor};
			cursor: pointer;
		}
	}

	textarea {
		${mixinInput}
		${mixinHoverAndFocus}
		
		min-height: 40px;
		resize: none;
		max-height: 200px;
		height: ${(props) => props.height + 'px'};
		line-height: 1.5em;
		padding: 8px 10px;
		overflow-y: hidden;

		&:hover {
			overflow-y: ${(props) => props.height > 60 && 'scroll'};
		}
	}

	.inputsRadioContainer {
		display: flex;
		margin: 2px 0 0 0;

		& .inputRadioContainer:last-child {
			margin: 0;
		}
	}

	.inputRadioContainer {
		display: flex;
		align-items: center;
		font-weight: ${(props) => props.theme.weight.regular};
		margin: 0 20px 0 0;
		line-height: 0;
		width: max-content;

		input {
			height: 30px;
		}

		@media (min-width: 768px) {
			margin: 0 40px 0 0;
		}
	}

	select {
		appearance: none;
		background: transparent;
		border: none;
		padding: 0 1em 0 10px;
		margin: 0;
		width: 100%;
		font-family: inherit;
		font-size: inherit;
		cursor: pointer;
		line-height: inherit;
		color: ${(props) => props.theme.gray1200};
		height: 40px;
		transition: all 0.2s;
		z-index: 1;

		&::-ms-expand {
			display: none;
		}

		outline: none;
	}

	.select {
		display: grid;
		grid-template-areas: 'select';
		align-items: center;
		position: relative;
		background: ${(props) => props.theme.white1500};
		border: 1px solid ${(props) => props.theme.gray1400};
		border-radius: 14px;
		font-family: ${(props) => props.theme.textFont};
		font-size: 0.938em;
		transition: all 0.2s;

		&:hover {
			border: 1px solid ${(props) => props.theme.brandColor};
		}

		select,
		&::after {
			grid-area: select;
		}

		&::after {
			content: '';
			justify-self: end;
			width: 0.8em;
			height: 0.5em;
			background: ${(props) => props.theme.gray1400};
			clip-path: polygon(0% 14%, 51% 88%, 100% 12%, 90% 12%, 51% 73%, 11% 15%);
			margin: 0 10px 0 0;
		}
	}

	select:focus {
		border: 1px solid ${(props) => props.theme.brandColor};
		background: ${(props) => props.theme.blue1500};
		color: ${(props) => props.theme.gray1500};
		outline: none;
	}

	select:focus + .focus {
		position: absolute;
		top: -1px;
		left: -1px;
		right: -1px;
		bottom: -1px;
		border-radius: inherit;
		border: 1px solid ${(props) => props.theme.brandColor};
	}

	.inputType {
		@supports (-webkit-appearance: none) or (-moz-appearance: none) {
			input[type='checkbox'],
			input[type='radio'] {
				appearance: none;
				height: 21px;
				outline: none;
				width: 21px;
				display: inline-block;
				vertical-align: top;
				position: relative;
				margin: 0 7px 0 0;
				cursor: pointer;
				border: 2px solid var(--bc, #6fa6f0);
				background: var(--b, ${(props) => props.theme.white1500});
				transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
				flex: 1 0 auto;

				&:after {
					content: '';
					display: block;
					left: -4px;
					top: -4px;
					position: absolute;
					transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
				}

				&:checked {
					--b: ${(props) => props.theme.brandColor};
					--bc: ${(props) => props.theme.brandColor};
					--d-o: 0.3s;
					--d-t: 0.6s;
					--d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
				}

				&:disabled {
					--b: ${(props) => props.theme.gray400Color};
					cursor: not-allowed;
					opacity: 0.9;

					&:checked {
						--b: ${(props) => props.theme.orange100};
						--bc: ${(props) => props.theme.gray400};
					}

					& + label {
						cursor: not-allowed;
					}
				}

				&:hover {
					&:not(:checked) {
						&:not(:disabled) {
							--bc: ${(props) => props.theme.brandColor};
						}
					}
				}

				&:focus {
					box-shadow: 0 0 0 2px ${(props) => props.theme.blue100};
				}

				&:not(.switch) {
					width: 21px;
					&:after {
						opacity: var(--o, 0);
					}
					&:checked {
						--o: 1;
					}
				}

				& + label {
					font-size: 14px;
					line-height: 21px;
					display: inline-block;
					vertical-align: top;
					cursor: pointer;
					margin-left: 4px;
				}

				@media (min-width: 7680px) {
					margin: 0 15px 0 0;
				}
			}

			input[type='checkbox'] {
				&:not(.switch) {
					border-radius: 7px;

					&:after {
						width: 5px;
						height: 9px;
						border: 2px solid ${(props) => props.theme.backgroundColor};
						border-top: 0;
						border-left: 0;
						left: 7px;
						top: 4px;
						transform: rotate(var(--r, 20deg));
					}

					&:checked {
						--r: 43deg;
					}
				}

				&.switch {
					width: 38px;
					border-radius: 11px;
					&:after {
						left: 2px;
						top: 2px;
						border-radius: 50%;
						width: 15px;
						height: 15px;
						background: var(--ab, ${(props) => props.theme.brandColor});
						transform: translateX(var(--x, 0));
					}

					&:checked {
						--ab: ${(props) => props.theme.backgroundColor};
						--x: 17px;
					}

					&:disabled {
						&:not(:checked) {
							&:after {
								opacity: 0.6;
							}
						}
					}
				}
			}

			input[type='radio'] {
				border-radius: 50%;

				&:after {
					width: 27px;
					height: 27px;
					border-radius: 50%;
					background: ${(props) => props.theme.whiteColor};
					opacity: 0;
					transform: scale(var(--s, 0.7));
				}

				&:checked {
					--s: 0.5;
				}
			}
		}
	}
`;
