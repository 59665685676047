import { createGlobalStyle } from 'styled-components';

const globalStyles = createGlobalStyle`
  *, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
  }

  html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    height: 100%;
    position: relative;
  }

  body {
    font-size: 15.5px;
    font-family: 'Open Sans', sans-serif;
    color: ${(props) => props.theme.backgroundColor};
    display: flex;
    flex-wrap: wrap;
    background: ${(props) => props.theme.black1500};
    scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
    max-width: 100%;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }

  header {
    flex: 0 0 auto;
  }

  main {
    flex: 1 0 auto;
    display: block;
  }

  footer {
    flex: 0 0 auto;
  }

  h1, h2, h3, h4, h5, h6, p, span {
    font-style: normal;
  }

  fieldset {
    border: none;
  }
  
  ol {
    list-style: auto;
  }

  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
  }

  img {
    border-style: none;
    display: block;
    line-height:0;
    object-fit: cover;
  }

  em, strong, address {
    font-style: normal;
  }

  strong {
    color: inherit;
  }

  mark {
    background: transparent;
    color: inherit;
  }

  button,
  select { /* 1 */
    text-transform: none;
  }

  button {
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  [hidden] {
    display: none;
  }

  *::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  *::-webkit-scrollbar:vertical {
    width:7px;
    background: transparent;
  }

  *::-webkit-scrollbar-button:increment,
  *::-webkit-scrollbar-button {
    display: none;
  } 

  *::-webkit-scrollbar:horizontal {
    height: 7px;
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.brandColor};
    border-radius: 14px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 7px;
  }

  svg {
    height: auto;
  }

  .icon {
    width: 20px;
    fill: ${(props) => props.theme.gray1200};
    flex: 0 0 auto;

    @media (min-width: 1200px) {
      width: 16px;
    }
  }

  .showNavbar {
    transform: translateX(0);
  }

  .truncate {
		display: inline;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 70%;
	}
`;

export default globalStyles;
