import styled from 'styled-components';

export const NavbarContainer = styled.nav`
	position: fixed;
	z-index: 2000;
	top: 0;
	left: 0;
	bottom: 0;
	background: #2a3138;
	width: 255px;
	padding: 35px 15px;
	display: flex;
	flex-direction: column;
	transform: translateX(-255px);
	transition: all 0.2s ease-in-out;

	section {
		margin: 30px 0 0 0;
	}

	h3 {
		font-family: ${(props) => props.theme.brandFont};
		font-size: 12px;
		font-weight: ${(props) => props.theme.weight.semiBold};
		color: ${(props) => props.theme.gray1500};
		text-transform: uppercase;
		margin: 0 0 15px 0;
	}

	ul {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin: 10px 0 0 0;
	}

	ul li button {
		display: flex;
		text-align: start;
		p {
			display: inline;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			will-change: padding-right;
			transition: all 0.1s;
		}

		&:hover {
			p {
				padding-right: 24px;
			}
		}
	}

	.logo {
		display: block;
		margin: 0 auto 5px auto;

		svg {
			height: 25px;
		}
	}

	.chatsSection {
		height: calc(100vh - ${(props) => props.delHeight + 'px'});

		&:hover {
			overflow-y: ${(props) => props.hoverUnlist && 'scroll'};
		}
	}

	.configurations {
		position: fixed;
		bottom: 0;
		margin: 0 0 35px 0;
	}

	@media (min-width: 1200px) {
		padding: 25px 15px;
		transform: translateX(0);

		.logo {
			margin: 0 auto -5px auto;

			svg {
				height: 30px;
			}
		}
	}
`;

export const Overlay = styled.div`
	background: ${(props) => props.theme.black1500};
	width: 100vw;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1500;
	background: rgba(6, 14, 24, 0.8);
	backdrop-filter: blur(5px);

	.close {
		position: absolute;
		right: calc(100vw - 300px);
		top: 15px;
	}

	@media (min-width: 1200px) {
		display: none;
	}
`;
