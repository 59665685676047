import { useContext } from 'react';
import { RegisterAndLoginWrapper } from '../../styles/generalStyles';
import { useNavigate } from 'react-router-dom';
import Logo from './../general/logo/Logo';
import { Button } from '../../styles/buttonGeneral';
import { ReactComponent as GoogleSVG } from './../../icons/google-fill.svg';
import { MainLoginContainer } from './styles/sMainLogin';
import Ellipse54 from './../../images/Ellipse-54.png';
import { signInWithGoogle } from './algorithms/signInWitGoogle';
import { AppContext } from '../../App';
import { useFirestore } from 'reactfire';

export default function MainLogin() {
	const db = useFirestore();
	const navigate = useNavigate();
	const { setLoading, setUser } = useContext(AppContext);

	return (
		<main>
			<RegisterAndLoginWrapper>
				<img className="background" src={Ellipse54} alt="background" />
				<MainLoginContainer>
					<Logo />
					<Button google onClick={() => signInWithGoogle({ db, setUser, setLoading, navigate })}>
						<GoogleSVG />
						Ingresar con Google
					</Button>
				</MainLoginContainer>
			</RegisterAndLoginWrapper>
		</main>
	);
}
