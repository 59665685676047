import styled from 'styled-components';

export const FooterContainer = styled.footer`
	position: fixed;
	bottom: 0;
	width: inherit;
	background: ${(props) => props.theme.black1500};

	@media (min-width: 1200px) {
		display: block;
		left: 255px;
		max-width: calc(100% - 255px);
	}
`;
