import styled from 'styled-components';

export const MobileHeaderContainer = styled.header`
	background: #2a3138;
	color: ${(props) => props.theme.gray1200};
	font-family: ${(props) => props.theme.titleFont};
	box-shadow: ${(props) => props.theme.shadowCard};
	position: fixed;
	width: inherit;
	z-index: 1000;

	svg {
		fill: ${(props) => props.theme.gray1200};
	}

	.headerContent {
		display: flex;
		justify-content: space-between;
		height: 44px;
		align-items: center;
	}

	@media (min-width: 1200px) {
		display: none;
	}
`;
