import { getDocs, collection, query, orderBy, limit } from 'firebase/firestore';
import { getTitles } from './getTitles';

export const getChats = async ({
	setChats,
	chats,
	setTitles,
	titles,
	setChatSelected,
	chatSelectedId = null,
	changeModel = false,
	setChatLoading = null,
	modelUsed,
	db,
	user,
}) => {
	const recoveredTitles =
		changeModel || titles.length === 0 ? await getTitles({ setTitles, setChatSelected, modelUsed, db, user }) : titles;
	if (recoveredTitles) {
		let chatsSanitized = null;
		if (chats?.[modelUsed.id]?.[chatSelectedId ?? recoveredTitles[0].chatId]?.length > 0) {
			chatsSanitized = chats[modelUsed.id][chatSelectedId ?? recoveredTitles[0].chatId];
		} else {
			const chatsRef = await getDocs(
				query(
					collection(db, 'AIColaboratorsChats', user.uid, modelUsed.id, chatSelectedId ?? recoveredTitles[0].chatId, 'chats'),
					orderBy('createAt', 'desc'),
					limit(10),
				),
			);
			chatsSanitized = chatsRef.docs
				.map((doc) => {
					return {
						...doc.data(),
						createAt: doc.data().createAt.toDate(),
					};
				})
				.reverse();
			setChatSelected((prev) => (!chatSelectedId || changeModel ? recoveredTitles[0] : prev));
		}
		setTitles(recoveredTitles);
		setChats((prev) => {
			return {
				...prev,
				[modelUsed.id]: {
					...(prev?.[modelUsed.id] ?? {}),
					...(!chatSelectedId ? { [recoveredTitles[0].chatId]: chatsSanitized } : { [chatSelectedId]: chatsSanitized }),
				},
			};
		});
	}
	setChatLoading && setChatLoading({ status: false, title: null });
};
